import { WebshopFunctionChildInterface, WebshopFunctionInterface } from "../webshop-function-interface"

export const subProductRelated1 : WebshopFunctionChildInterface = {
  name: "Beépített választható kinézetek",
  description: "",
  important: false
}

export const subProductRelated2 : WebshopFunctionChildInterface = {
  name: "Ajánlók, TOP termékek",
  description: "",
  important: false
}

export const subProductRelated3 : WebshopFunctionChildInterface = {
  name: "Arculati elemek",
  description: "",
  important: false
}

export const subProductRelated4 : WebshopFunctionChildInterface = {
  name: "Teljeskörűen testreszabható kezdőoldali banner",
  description: "",
  important: false
}

export const subProductRelated5 : WebshopFunctionChildInterface = {
  name: "Módosítható lábléc",
  description: "",
  important: false
}

export const subProductRelated6 : WebshopFunctionChildInterface = {
  name: "Mobil eszközökre is optimalizált webáruház, reszponzív kinézetek",
  description: "",
  important: false
}

export const subProductRelated7 : WebshopFunctionChildInterface = {
  name: "PWA (Progressive Web Apps): okos telefonra telepíthető a webáruház ",
  description: "",
  important: false
}

export const subProductRelated8 : WebshopFunctionChildInterface = {
  name: "Egyedi kinézet, saját arculat, egyedi szűrők",
  description: "",
  important: false
}

export const appearenceRelated : WebshopFunctionInterface = {
  name: "Megjelenéssel kapcsolatos funkciók",
  children: [
    subProductRelated1,
    subProductRelated2,
    subProductRelated3,
    subProductRelated4,
    subProductRelated5,
    subProductRelated6,
    subProductRelated7,
    subProductRelated8,
  ]
}
