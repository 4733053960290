import { WebshopFunctionChildInterface, WebshopFunctionInterface } from "../webshop-function-interface"

export const subProductRelated1 : WebshopFunctionChildInterface = {
  name: "Keresőoptimalizálás, SEO",
  description: "",
  important: false
}

export const subProductRelated2 : WebshopFunctionChildInterface = {
  name: "Kuponrendszer",
  description: "",
  important: false
}

export const subProductRelated3 : WebshopFunctionChildInterface = {
  name: "Cross-sale és Up-sale",
  description: "",
  important: false
}

export const subProductRelated4 : WebshopFunctionChildInterface = {
  name: "Kosárba rakás után felugró ajánlatok",
  description: "",
  important: false
}

export const subProductRelated5 : WebshopFunctionChildInterface = {
  name: "Árcsökkenés értesítő",
  description: "",
  important: false
}

export const subProductRelated6 : WebshopFunctionChildInterface = {
  name: "Készletértesítő",
  description: "",
  important: false
}

export const subProductRelated7 : WebshopFunctionChildInterface = {
  name: "Utóajánlat",
  description: "",
  important: false
}

export const insideMarketing : WebshopFunctionInterface = {
  name: "Beépített marketing funkciók",
  children: [
    subProductRelated1,
    subProductRelated2,
    subProductRelated3,
    subProductRelated4,
    subProductRelated5,
    subProductRelated6,
    subProductRelated7,
  ]
}
