import * as React from "react"
import Layout from "../components/layout"
import WebshopFunctions from "../components/webshop-functions/webshop-functions"
import ServiceWave from "../components/waves/service-wave"
import MetaData from "../components/metadata"
import StructuredData from "../components/structured-data"

const WebshopFunctionsPage = (props) => {
  return <Layout subHeader>
    <MetaData title={'Wesbhop funkciói'} url={props.location.href} description={"Alphapro webshop, egy SEO optimalizált, nagy teherbírású, profi webáruház. Az itt felsorolt funkciók az alap csomag részét képezik."}/>
    <StructuredData title={'Wesbhop funkciói'} url={props.location.href} description={"Alphapro webshop, egy SEO optimalizált, nagy teherbírású, profi webáruház. Az itt felsorolt funkciók az alap csomag részét képezik."}/>
    <ServiceWave bg="/images/pic_bg_2.jpg" />
    <WebshopFunctions />
  </Layout>
}

export default WebshopFunctionsPage
