import { WebshopFunctionChildInterface, WebshopFunctionInterface } from "../webshop-function-interface"

export const subAdmin1 : WebshopFunctionChildInterface = {
  name: "Könnyen kezelhető, biztonságos adminisztrációs felület",
  description: "",
  important: false
}
export const subAdmin2 : WebshopFunctionChildInterface = {
  name: "Korlátlan felhasználó- és jogosultságkezelés",
  description: "",
  important: false
}
export const subAdmin3 : WebshopFunctionChildInterface = {
  name: "Az egész rendszert átfogó súgó",
  description: "",
  important: false
}
export const subAdmin4 : WebshopFunctionChildInterface = {
  name: "Telefon és Email ügyfélszolgálat",
  description: "",
  important: false
}
export const subAdmin5 : WebshopFunctionChildInterface = {
  name: "Dinamikus cloud(felhő) tárhely, korlátlan erőforrással",
  description: "",
  important: false
}
export const subAdmin6 : WebshopFunctionChildInterface = {
  name: "Titkosított SSL (https) kommunikáció, biztonságos kapcsolat",
  description: "",
  important: false
}
export const subAdmin7 : WebshopFunctionChildInterface = {
  name: "Automatikus figyelmeztetések",
  description: "",
  important: false
}
export const subAdmin8 : WebshopFunctionChildInterface = {
  name: "Beépített API Octopus 8 ERP vállalatirányítási rendszerhez, igény esetén bővíthető",
  description: "",
  important: false
}
export const subAdmin9 : WebshopFunctionChildInterface = {
  name: "Domain és Email cím beállítása",
  description: "",
  important: false
}
export const subAdmin10 : WebshopFunctionChildInterface = {
  name: "Egyedi erőforrás",
  description: "",
  important: false
}

export const admin : WebshopFunctionInterface = {
  name: "Adminisztrációs felület, tudástár és technikai háttér",
  children: [
    subAdmin1,
    subAdmin2,
    subAdmin3,
    subAdmin4,
    subAdmin5,
    subAdmin6,
    subAdmin7,
    subAdmin8,
    subAdmin9,
    subAdmin10,
  ]
}

