import * as React from "react"
import { makeStyles } from "@material-ui/core"
import WebshopFunction from "./webshop-function"
import { admin } from "../../interfaces/webshop-function-interface/used_by/admin-webshop-functions"
import { productRelated } from "../../interfaces/webshop-function-interface/used_by/product-related-webshop-functions"
import { search } from "../../interfaces/webshop-function-interface/used_by/search-webshop-functions"
import { userRelated } from "../../interfaces/webshop-function-interface/used_by/user-related-webshop-functions"
import { orderRelated } from "../../interfaces/webshop-function-interface/used_by/order-related-webshop-functions"
import { insideMarketing } from "../../interfaces/webshop-function-interface/used_by/inside-marketing-webshop-functions"
import { outsideMarketing } from "../../interfaces/webshop-function-interface/used_by/outside-marketing-webshop-functions"
import { appearenceRelated } from "../../interfaces/webshop-function-interface/used_by/apperaence-related-webshop-functions"
import { otherContents } from "../../interfaces/webshop-function-interface/used_by/other-contents-webshop-functions"
import { support } from "../../interfaces/webshop-function-interface/used_by/support-webshop-functions"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url("/images/wave_bg_6.jpg")`,
    transition: "background 5s linear",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 25,
  },
  content: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 1200,
    margin: 'auto',
    [theme.breakpoints.down(1000)]: {
      width: '90%'
    },
    [theme.breakpoints.down(600)]: {
      width: '100%'
    },
    zIndex: 15,
  },
  grow: {
    flex: 1
  },
  divTexts: {
    width: "100%",
    textAlign: 'right',
    marginTop: '-100px',
    [theme.breakpoints.down(730)]: {
      marginTop: '-50px',
    },
    [theme.breakpoints.down(520)]: {
      marginTop: '-20px',
    },
    [theme.breakpoints.down(450)]: {
      textAlign: 'center',
      marginTop: '0px',
    },
  },
  onTopTitle: {
    fontSize: 40,
    fontWeight: 700,
    marginTop: 0,
    marginBottom: 0,
  },
  primarySpan: {
    color: theme.palette.primary.main,
  },
  onTopDescription: {
    marginTop: '10px',
    marginBottom: '50px',
  },
}))

const WebshopFunctions = () => {
  const classes = useStyles()

  return <div className={classes.root}>
    <div className={classes.content}>
      <ServicesTexts/>
      <WebshopFunction wf={admin} open={true} />
      <WebshopFunction wf={productRelated} />
      <WebshopFunction wf={search} />
      <WebshopFunction wf={userRelated} />
      <WebshopFunction wf={orderRelated} />
      <WebshopFunction wf={insideMarketing} />
      <WebshopFunction wf={outsideMarketing} />
      <WebshopFunction wf={appearenceRelated} />
      <WebshopFunction wf={otherContents} />
      <WebshopFunction wf={support} />
    </div>
  </div>
}

const ServicesTexts = () => {
  const classes = useStyles()
  return <div className={classes.divTexts}>
    <h1 className={classes.onTopTitle}>
      <span className={classes.primarySpan}>A webshop</span><br/> funkciói
    </h1>
    <div className={classes.onTopDescription}>
      A felsorolt funkciók az<br/>
      alap csomag részét képezik
    </div>
  </div>
}

export default WebshopFunctions
