import { WebshopFunctionChildInterface, WebshopFunctionInterface } from "../webshop-function-interface"

export const subProductRelated1 : WebshopFunctionChildInterface = {
  name: "Kosaras vásárlási rendszer",
  description: "",
  important: false
}

export const subProductRelated2 : WebshopFunctionChildInterface = {
  name: "Intelligens vásárlási folyamat",
  description: "",
  important: false
}

export const subProductRelated3 : WebshopFunctionChildInterface = {
  name: "Szállítási, fizetési módok beállítása",
  description: "",
  important: false
}

export const subProductRelated4 : WebshopFunctionChildInterface = {
  name: "Szállítási költségek megadása",
  description: "",
  important: false
}

export const subProductRelated5 : WebshopFunctionChildInterface = {
  name: "Rendelések kezelése",
  description: "",
  important: false
}

export const subProductRelated6 : WebshopFunctionChildInterface = {
  name: "Rendelések státuszának kezelése",
  description: "",
  important: false
}

export const subProductRelated7 : WebshopFunctionChildInterface = {
  name: "Megrendelés nyomtatása",
  description: "",
  important: false
}

export const subProductRelated8 : WebshopFunctionChildInterface = {
  name: "Megrendelések exportálása",
  description: "",
  important: false
}

export const subProductRelated9 : WebshopFunctionChildInterface = {
  name: "Megrendelések manuális rögzítése",
  description: "",
  important: false
}

export const subProductRelated10 : WebshopFunctionChildInterface = {
  name: "Több, különálló szállítási cím kezelése",
  description: "",
  important: false
}

export const subProductRelated11 : WebshopFunctionChildInterface = {
  name: "Integrált szállítási megoldások - házhozszállítás futárszolgálatokkal",
  description: "",
  important: false
}

export const subProductRelated12 : WebshopFunctionChildInterface = {
  name: "Integrált szállítási megoldások - csomagpontra történő szállítás",
  description: "",
  important: false
}

export const subProductRelated13 : WebshopFunctionChildInterface = {
  name: "Integrált szállítási megoldások - csomagautomatára történő szállítás",
  description: "",
  important: false
}

export const subProductRelated14 : WebshopFunctionChildInterface = {
  name: "Integrált fizetési megoldások - hagyományos banki megoldások: simplepay, stb.",
  description: "",
  important: false
}

export const subProductRelated15 : WebshopFunctionChildInterface = {
  name: "Integrált fizetési megoldások - áruhitel, személyi kölcsön: Cofidis, Otp hitel, Cetelem",
  description: "",
  important: false
}

export const subProductRelated16 : WebshopFunctionChildInterface = {
  name: "Bolti átvétel kezelése",
  description: "",
  important: false
}

export const subProductRelated17 : WebshopFunctionChildInterface = {
  name: "Szállítási és Fizetési módhoz minimális és maximális tömeg és összeg",
  description: "",
  important: false
}

export const subProductRelated18 : WebshopFunctionChildInterface = {
  name: "Szállítási és fizetési módok összekapcsolása",
  description: "",
  important: false
}

export const subProductRelated19 : WebshopFunctionChildInterface = {
  name: "Árajánlat készítése",
  description: "",
  important: false
}

export const subProductRelated20 : WebshopFunctionChildInterface = {
  name: "Megrendelés statisztika",
  description: "",
  important: false
}

export const subProductRelated21 : WebshopFunctionChildInterface = {
  name: "Beszerzés",
  description: "",
  important: false
}

export const orderRelated : WebshopFunctionInterface = {
  name: "Megrendelésekkel kapcsolatos funkciók",
  children: [
    subProductRelated1,
    subProductRelated2,
    subProductRelated3,
    subProductRelated4,
    subProductRelated5,
    subProductRelated6,
    subProductRelated7,
    subProductRelated8,
    subProductRelated9,
    subProductRelated10,
    subProductRelated11,
    subProductRelated12,
    subProductRelated13,
    subProductRelated14,
    subProductRelated15,
    subProductRelated16,
    subProductRelated17,
    subProductRelated18,
    subProductRelated18,
    subProductRelated20,
    subProductRelated21,
  ]
}
