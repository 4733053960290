import * as React from "react"
import { makeStyles, useMediaQuery } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  svg: {
    maxHeight: 480,
    marginBottom: -10,
    width: '100%'
  },
  divSvg: {
    height: "100%",
    overflow: "hidden",
    //backgroundColor: "#ECF2F5",
    width: "100%",
    gridColumn: "1 / 1",
    gridRow: "1 / 1",
    minHeight: 360,
    backdropFilter: 'blur(8px)',
    [theme.breakpoints.down(905)]: {
      display: 'flex',
      alignItems: 'flex-end'
    },
    zIndex:10
  },
  divImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 5,
  },
  divTop: {
    display: "grid",
    gridTemplate: "1fr/1fr",
    placeItems: "center",
    maxHeight: 480,
    marginTop: -30
  },
  logoImage: {
    marginBottom: 0
  },
  bgWave: {
    stroke: "none",
    fill: "#ECF2F5",
  },

}))

const ServiceWave = ({ bg }) => {
  const classes = useStyles()
  const matches = useMediaQuery('(min-width:1200px)');

  return <div className={classes.divTop} style={{ background: `url(${bg}) no-repeat center center`, backgroundSize: 'cover' }}>
    <div className={classes.divSvg} style={matches? { background: `url(${bg}) no-repeat center center` } : {}}>
      <svg viewBox="0 0 370 150" preserveAspectRatio="none"
           className={classes.svg}>
        <path
          d="M0,106 C210,215 399,-150 500,258 L500,150 L0,150 Z"
          className={classes.bgWave} />
      </svg>
    </div>
  </div>
}

export default ServiceWave
