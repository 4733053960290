import { WebshopFunctionChildInterface, WebshopFunctionInterface } from "../webshop-function-interface"

export const subProductRelated1 : WebshopFunctionChildInterface = {
  name: "Márka aloldalak létrehozási lehetőség",
  description: "",
  important: false
}

export const subProductRelated2 : WebshopFunctionChildInterface = {
  name: "Landoló oldalak",
  description: "",
  important: false
}

export const subProductRelated3 : WebshopFunctionChildInterface = {
  name: "Tetszőleges számú csempe kihelyezése, egyedi elnevezéssel, egyedi linkkel",
  description: "",
  important: false
}

export const subProductRelated4 : WebshopFunctionChildInterface = {
  name: "Tetszőleges tartalmú felugró abalak létrehozása (beállítható hogy milyen oldalakon jelenjen meg a dátum tól/ig időszakban)",
  description: "",
  important: false
}

export const subProductRelated5 : WebshopFunctionChildInterface = {
  name: "Hírek, Naptár",
  description: "",
  important: false
}

export const subProductRelated6 : WebshopFunctionChildInterface = {
  name: "Szerkeszthető tartalmak",
  description: "",
  important: false
}

export const subProductRelated7 : WebshopFunctionChildInterface = {
  name: "Külső szereplők tájékoztató szövegei",
  description: "",
  important: false
}

export const otherContents : WebshopFunctionInterface = {
  name: "Egyéb tartalmak",
  children: [
    subProductRelated1,
    subProductRelated2,
    subProductRelated3,
    subProductRelated4,
    subProductRelated5,
    subProductRelated6,
    subProductRelated7,
  ]
}
