import { WebshopFunctionChildInterface, WebshopFunctionInterface } from "../webshop-function-interface"

export const subProductRelated1 : WebshopFunctionChildInterface = {
  name: "Hasonló termékek",
  description: "",
  important: false
}

export const subProductRelated2 : WebshopFunctionChildInterface = {
  name: "Elasticsearch",
  description: "",
  important: false
}

export const subProductRelated3 : WebshopFunctionChildInterface = {
  name: "Keresési javaslat",
  description: "",
  important: false
}

export const subProductRelated4 : WebshopFunctionChildInterface = {
  name: "Keresőben marketing célú ajánlások (plusz bevétel)",
  description: "",
  important: false
}

export const subProductRelated5 : WebshopFunctionChildInterface = {
  name: "Hírekben keresés",
  description: "",
  important: false
}

export const subProductRelated6 : WebshopFunctionChildInterface = {
  name: "Termék kategóriákban keresés",
  description: "",
  important: false
}

export const subProductRelated7 : WebshopFunctionChildInterface = {
  name: "Népszerű keresések",
  description: "",
  important: false
}

export const subProductRelated8 : WebshopFunctionChildInterface = {
  name: "Keresési szinonimák kezelése",
  description: "",
  important: false
}

export const subProductRelated9 : WebshopFunctionChildInterface = {
  name: "Keresés szinonima szótár alapján",
  description: "",
  important: false
}

export const subProductRelated10 : WebshopFunctionChildInterface = {
  name: "Elgépelések kezelése, pl.: drll lapot",
  description: "",
  important: false
}

export const subProductRelated11 : WebshopFunctionChildInterface = {
  name: "Ragozások kezelése, pl.: laptopocska",
  description: "",
  important: false
}

export const subProductRelated12 : WebshopFunctionChildInterface = {
  name: "Nagyon pontos találat",
  description: "",
  important: false
}

export const search : WebshopFunctionInterface = {
  name: "Kereső funkciók",
  children: [
    subProductRelated1,
    subProductRelated2,
    subProductRelated3,
    subProductRelated4,
    subProductRelated5,
    subProductRelated6,
    subProductRelated7,
    subProductRelated8,
    subProductRelated9,
    subProductRelated10,
    subProductRelated11,
    subProductRelated12,
  ]
}
