import { WebshopFunctionChildInterface, WebshopFunctionInterface } from "../webshop-function-interface"

export const subProductRelated1 : WebshopFunctionChildInterface = {
  name: "Statisztika, Naplózás",
  description: "",
  important: false
}

export const subProductRelated2 : WebshopFunctionChildInterface = {
  name: "Tartalom védelem",
  description: "",
  important: false
}

export const subProductRelated3 : WebshopFunctionChildInterface = {
  name: "Munkaszüneti nap, nyitvatartás kezelés",
  description: "",
  important: false
}

export const subProductRelated4 : WebshopFunctionChildInterface = {
  name: "Jogi dokumentumok kezelése, ÁSZF, Adatkezelési tájékoztató",
  description: "",
  important: false
}

export const subProductRelated5 : WebshopFunctionChildInterface = {
  name: "Adatkezelési beállítások (GDPR kompatibilis)",
  description: "",
  important: false
}

export const subProductRelated6 : WebshopFunctionChildInterface = {
  name: "Cookie kezelés",
  description: "",
  important: false
}

export const subProductRelated7 : WebshopFunctionChildInterface = {
  name: "Külső statisztika, Elemzés - Google Analytics, Search Console",
  description: "",
  important: false
}

export const subProductRelated8 : WebshopFunctionChildInterface = {
  name: "Külső statisztika, Elemzés - TAG Manager, Facebook Pixel, Egyéb megoldások",
  description: "",
  important: false
}

export const subProductRelated9 : WebshopFunctionChildInterface = {
  name: "Számlázás: külső számlázó programokkal integrálható",
  description: "",
  important: false
}

export const subProductRelated10 : WebshopFunctionChildInterface = {
  name: "Piactér megjelenések",
  description: "",
  important: false
}

export const support : WebshopFunctionInterface = {
  name: "Támogató funkciók",
  children: [
    subProductRelated1,
    subProductRelated2,
    subProductRelated3,
    subProductRelated4,
    subProductRelated5,
    subProductRelated6,
    subProductRelated7,
    subProductRelated8,
    subProductRelated9,
    subProductRelated10,
  ]
}
