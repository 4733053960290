import { WebshopFunctionChildInterface, WebshopFunctionInterface } from "../webshop-function-interface"

export const subProductRelated1 : WebshopFunctionChildInterface = {
  name: "Vásárló regisztráció",
  description: "",
  important: false
}

export const subProductRelated2 : WebshopFunctionChildInterface = {
  name: "Automatikus regsiztráció vásárlás után",
  description: "",
  important: false
}

export const subProductRelated3 : WebshopFunctionChildInterface = {
  name: "Vásárlói interakciók után értesítő emailek",
  description: "",
  important: false
}

export const subProductRelated4 : WebshopFunctionChildInterface = {
  name: "Kapcsolat a vásárlóval",
  description: "",
  important: false
}

export const subProductRelated5 : WebshopFunctionChildInterface = {
  name: "Belépés engedélyezése / tiltása",
  description: "",
  important: false
}

export const subProductRelated6 : WebshopFunctionChildInterface = {
  name: "Kedvencek",
  description: "",
  important: false
}

export const subProductRelated7 : WebshopFunctionChildInterface = {
  name: "Kosár eltárolása",
  description: "",
  important: false
}

export const subProductRelated8 : WebshopFunctionChildInterface = {
  name: "Gyorsrendelés korábbi megrendelés alapján",
  description: "",
  important: false
}

export const subProductRelated9 : WebshopFunctionChildInterface = {
  name: "Vásárlói adatbázis export",
  description: "",
  important: false
}

export const subProductRelated10 : WebshopFunctionChildInterface = {
  name: "Manuális vásárló rögzítés",
  description: "",
  important: false
}

export const subProductRelated11 : WebshopFunctionChildInterface = {
  name: "Facebook login, Google login",
  description: "",
  important: false
}

export const subProductRelated12 : WebshopFunctionChildInterface = {
  name: "Félbemaradt vásárlás után bizonyos idővel emlékeztető email a vevőnek",
  description: "",
  important: false
}

export const subProductRelated13 : WebshopFunctionChildInterface = {
  name: "Adószám ellenőrzése",
  description: "",
  important: false
}

export const userRelated : WebshopFunctionInterface = {
  name: "Vásárlókkal kapcsolatos funkciók",
  children: [
    subProductRelated1,
    subProductRelated2,
    subProductRelated3,
    subProductRelated4,
    subProductRelated5,
    subProductRelated6,
    subProductRelated7,
    subProductRelated8,
    subProductRelated9,
    subProductRelated10,
    subProductRelated11,
    subProductRelated12,
    subProductRelated13,
  ]
}
