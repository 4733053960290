import * as React from "react"
import { Collapse, Divider, makeStyles } from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import { useState } from "react"
import {
  WebshopFunctionChildInterface
} from "../../interfaces/webshop-function-interface/webshop-function-interface"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FunctionsIcon from "@material-ui/icons/Functions"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  line: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 25,
    paddingRight: 25
  },
  grow: {
    flex: 1
  },
  description: {
    marginLeft: 5,
    color: "grey",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  divider: {
    margin: "auto",
    width: "85%",
    marginTop: 10,
    marginBottom: 5
  },
  iconAndDesc: {
    margin: "auto",
    width: "85%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  }
}))

interface WebshopFunctionChildProps {
  wf: WebshopFunctionChildInterface;
  greyBg: boolean;
}

const WebshopFunctionChild = (props: WebshopFunctionChildProps) => {
  const classes = useStyles()
  const [clicked, setClicked] = useState(false)

  return <div className={classes.root}
              style={props.greyBg ? { backgroundColor: "#fff" } : {}}
              onClick={() => setClicked(!clicked)}>
    <div className={classes.line}>
      {/*{clicked ? <ExpandMoreIcon color="primary" /> : <ChevronRightIcon color="primary"/>}*/}
      <div style={props.wf.important ? { color: "#00b790" } : {}}>{props.wf.name}</div>
      <div className={classes.grow} />
      {/*{clicked ? <MoreVertIcon style={{ color: "lightgrey" }} /> : <MoreHorizIcon style={{ color: "lightgrey" }} />}*/}
    </div>
    {props.wf.description.length > 0 &&
      <Collapse in={clicked} style={{ width: "100%", margin: "auto" }}>
        <Divider className={classes.divider} />
        <div className={classes.iconAndDesc}>
          <div>
            <FunctionsIcon color="primary" />
          </div>
          <div className={classes.description}>
            {props.wf.description}
          </div>
        </div>
      </Collapse>
    }
  </div>
}

export default WebshopFunctionChild
