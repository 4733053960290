import * as React from "react"
import { useState } from "react"
import { Collapse, makeStyles } from "@material-ui/core"
import ChevronRight from "@material-ui/icons/ChevronRight"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import { WebshopFunctionInterface } from "../../interfaces/webshop-function-interface/webshop-function-interface"
import WebshopFunctionChild from "./webshop-function-child"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10
  },
  line: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "linear-gradient(to right, #27A1B9 , #47AA6B)",
    minHeight: 60,
    borderRadius: 50,
    paddingLeft: 25,
    paddingRight: 25,
    cursor: "pointer",
    flex: '0 0 100%'
  },
  grow: {
    flex: 1
  },
  title: {
    color: "white",
    fontWeight: "bold",
    fontSize: 15,

  },
  divImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 10
  },
  img: {
    width: 70,
    height: 22,
    marginBottom: 0
  },
  rootClicked: {
    marginBottom: 20
  },
  whiteIcon: {
    color: "white",
    marginRight: '5px'
  },
  collapse: {
    width: "99%",
    margin: "auto",
    marginTop: 15,
  }
}))

interface WebshopFunctionProps {
  wf: WebshopFunctionInterface;
  open?: boolean;
}

const WebshopFunction = (props: WebshopFunctionProps) => {
  const classes = useStyles()
  const [clicked, setClicked] = useState(props.open || false)

  return <div className={`${classes.root} ${clicked ? classes.rootClicked : ""}`}>
    {/*<div className={classes.divImg}>
      <img src="/images/xo_icons_2.png" className={classes.img}  alt={'Xo'}/>
    </div>*/}
    <div className={classes.line} onClick={() => setClicked(!clicked)}>
      {clicked ? <KeyboardArrowDown className={classes.whiteIcon} /> : <ChevronRight className={classes.whiteIcon} />}
      <h2 className={classes.title}>{props.wf.name}</h2>
      <div className={classes.grow} />
    </div>
    <Collapse in={clicked} className={classes.collapse}>
      {props.wf.children.map((w, i) => {
        return <WebshopFunctionChild wf={w} greyBg={i % 2 == 1} key={i} />
      })}
    </Collapse>
  </div>
}

export default WebshopFunction
