import { WebshopFunctionChildInterface, WebshopFunctionInterface } from "../webshop-function-interface"

export const subProductRelated1 : WebshopFunctionChildInterface = {
  name: "Termékek kezelése, létrehozása, módosítása",
  description: "",
  important: false
}
export const subProductRelated2 : WebshopFunctionChildInterface = {
  name: "Adatbázis export/import",
  description: "",
  important: false
}
export const subProductRelated3 : WebshopFunctionChildInterface = {
  name: "Termék leírások, információk megadási lehetőség",
  description: "",
  important: false
}
export const subProductRelated4 : WebshopFunctionChildInterface = {
  name: "Termékhez képek, dokumentumok feltöltési lehetőség",
  description: "",
  important: false
}
export const subProductRelated5 : WebshopFunctionChildInterface = {
  name: "Kategóriák, termékcsoportok, státusz alapú csoportosítás",
  description: "",
  important: false
}
export const subProductRelated6 : WebshopFunctionChildInterface = {
  name: "Termék státuszok",
  description: "",
  important: false
}
export const subProductRelated7 : WebshopFunctionChildInterface = {
  name: "Kapcsolódó termékek",
  description: "",
  important: false
}
export const subProductRelated8 : WebshopFunctionChildInterface = {
  name: "Kiegészítő termékek",
  description: "",
  important: false
}
export const subProductRelated9 : WebshopFunctionChildInterface = {
  name: "Termék véleményezés, vásárlói visszajelzés (review)",
  description: "",
  important: false
}
export const subProductRelated10 : WebshopFunctionChildInterface = {
  name: "Egyéb termékmegjelenítési leheetőségek: slider, dinamikus csempék",
  description: "",
  important: false
}
export const subProductRelated11 : WebshopFunctionChildInterface = {
  name: "Egyéb termékmegjelenítési leheetőségek: slider, dinamikus csempék",
  description: "",
  important: false
}
export const subProductRelated12 : WebshopFunctionChildInterface = {
  name: "Egyéb termékmegjelenítési leheetőségek: slider, dinamikus csempék",
  description: "",
  important: false
}
export const subProductRelated13 : WebshopFunctionChildInterface = {
  name: "Raktárkészlet kezelés, szállítmányok rögzítése",
  description: "",
  important: false
}
export const subProductRelated14 : WebshopFunctionChildInterface = {
  name: "Termék paraméterek",
  description: "",
  important: false
}
export const subProductRelated15 : WebshopFunctionChildInterface = {
  name: "Egy termék több kategóriába is besorolható",
  description: "",
  important: false
}
export const subProductRelated16 : WebshopFunctionChildInterface = {
  name: "Tulajdonság szerinti és összetett termékszűrés",
  description: "",
  important: false
}
export const subProductRelated17 : WebshopFunctionChildInterface = {
  name: "Termék akciós ár megadási lehetőság (dátum -tól/-ig), automatikus akció indítás és visszaállítás",
  description: "",
  important: false
}
export const subProductRelated18 : WebshopFunctionChildInterface = {
  name: "Online letölthető termékek kezelése",
  description: "",
  important: false
}
export const subProductRelated19 : WebshopFunctionChildInterface = {
  name: "Nyomtatható árlista termékekről",
  description: "",
  important: false
}
export const subProductRelated20 : WebshopFunctionChildInterface = {
  name: "Termékek excelbe exportálhatóak",
  description: "",
  important: false
}
export const subProductRelated21 : WebshopFunctionChildInterface = {
  name: "Termékek pdf-be exportálhatóak",
  description: "",
  important: false
}
export const subProductRelated22 : WebshopFunctionChildInterface = {
  name: "Termék kategóriánként megadható egyedi szállítási díj (csoportosan)",
  description: "",
  important: false
}
export const subProductRelated23 : WebshopFunctionChildInterface = {
  name: "Csoportos fizetési mód beállítás",
  description: "",
  important: false
}
export const subProductRelated24 : WebshopFunctionChildInterface = {
  name: "Csoportos árösszehasonlító export feed beállítás",
  description: "",
  important: false
}
export const subProductRelated25 : WebshopFunctionChildInterface = {
  name: "Részletes termék teljesítmény statisztika",
  description: "",
  important: false
}
export const subProductRelated26 : WebshopFunctionChildInterface = {
  name: "Nagy felbontású termék képek feltöltési lehetőség",
  description: "",
  important: false
}

export const productRelated : WebshopFunctionInterface = {
  name: "Termékekkel kapcsolatos funkciók",
  children: [
    subProductRelated1,
    subProductRelated2,
    subProductRelated3,
    subProductRelated4,
    subProductRelated5,
    subProductRelated6,
    subProductRelated7,
    subProductRelated8,
    subProductRelated9,
    subProductRelated10,
    subProductRelated11,
    subProductRelated12,
    subProductRelated13,
    subProductRelated14,
    subProductRelated15,
    subProductRelated16,
    subProductRelated17,
    subProductRelated18,
    subProductRelated19,
    subProductRelated20,
    subProductRelated21,
    subProductRelated22,
    subProductRelated23,
    subProductRelated24,
    subProductRelated25,
    subProductRelated26,
  ]
}

